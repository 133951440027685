<template>
  <Card>
    <template #body>
      <v-row>
        <v-col md="6"> </v-col>
        <v-col md="6"> </v-col>
      </v-row>
    </template>
  </Card>
</template>
<script>
import Card from "../Components/Card";
import requestService from "../../../services/request.service";
export default {
  components: {
    Card,
  },
  data() {
    return {
      items: [],

      tax: 0,
    };
  },
  mounted() {
    document.title = this.$i18n.t("CreateRequest");
    this.$store.commit("SET_CARD", this.card);
    this.$store.commit("SET_CARD_LOADING", true);
    return requestService.details().then(
      (response) => {
        this.$store.commit("SET_CARD_LOADING", false);
        // this.items = response.data.data;
        var tax = response.data.setting;
        var self = this;

        // setting.forEach(v => {
        //     if (v.name == 'tax') {
        //         self.tax =  v.value
        //     }
        //     if (v.name == 'location') {
        //         self.location =  v.value
        //     }

        // });
      },
      (error) => {
        this.$store.commit("SET_CARD_LOADING", false);
        // console.log(error);
      }
    );
  },
};
</script>